// Variables

$basefont: "neue-haas-grotesk-text", sans-serif;
$headingfont: "neue-haas-grotesk-display", sans-serif;
$article-width: 100rem;
$textcolor: #000;
$linkcolor: #000;
$outermargin: clamp(15px, 2vw, 1.5rem);
$gridspacing: clamp(8px, 2vw, 15px);
$mediumgridspacing: clamp(8px, calc(6vw - 20px), 300px);
$largegridspacing: clamp(8px, calc(9vw - 20px), 300px);

// Mixins

@mixin hyphernation($property) {
	hyphens: $property;
	-webkit-hyphens: $property;
	-ms-hyphens: $property;
	-o-hyphens: $property;
}

@mixin font($family, $weight, $style, $size, $lineheight) {
	font-family: $family;
	font-weight: $weight;
	font-style: $style;
	font-size: $size;
	line-height: $lineheight;
}

@mixin aniline {
	display: inline;
	color: inherit;
	background-image: linear-gradient(rgb(0, 0, 0), rgb(0, 0, 0));
	background-size: 0 0.1em;
	background-position: 0 100%;
	background-repeat: no-repeat;
	transition: background-size 200ms;
	padding-bottom: 0.05em;
	margin-bottom: -0.05em;
}

@mixin aniline-hover {
	background-size: 100% 0.1em;
}

@import 'graphics';
@import 'normalize';

/* Main layout. */

html {
	font-size: 19px; /* Set base for rem */
	height: 100%;
}

body {
	@include font($basefont, 400, normal, 18px, 1.5);
	color: $textcolor;
	background-color: #fff;
	display: flex;
	flex-direction: column;
	align-items: stretch;
	min-height: 100vh;
}

header {
	display: grid;
	grid-template-columns: 1fr 5rem;
	column-gap: clamp(15px, 2vw, 1.5rem);
	width: 100%;
	min-height: 5rem;
	min-height: clamp(3.7rem, 3vw, 4rem);
	margin: 0 auto;
	z-index: 20;
	font-weight: 500;
	position: fixed;
}

header * {
	opacity: 1;
	transition: 0.2s opacity;
}

header.hide * {
	opacity: 0;
	transition: 0.2s opacity;
}

@media (max-width: 1000px) {
	header {
		grid-template-columns: 1fr 4rem;
	}
}

main {
	margin: 0;
	margin-top: $outermargin;
	margin-top: clamp(3.5rem, 10vw, 5rem);
	margin-left: $outermargin;
	margin-right: $outermargin;
	flex: 1;
	z-index: 10;
}

footer {
	z-index: 2;
	margin: 3rem $outermargin $outermargin $outermargin;

}

article {
	max-width: $article-width;
	margin: clamp(2rem, 5vw, 5rem) auto 0 auto;
}

/* Burger icon */

#menu-icon {
	position: relative;
	display: none;
	width: 2.2rem;
	height: 2.2rem;
	margin-top: clamp(17px, 3vw, 27px);
	margin-right: clamp(15px, 2vw, 1.5rem);
	z-index: 32;
	cursor: pointer;
	align-self: start;
	justify-self: end;

	&::before, &::after {
		content: '';
		position: absolute;
		width: 100%;
		height: 3px;
		background-color: #000;
		transition: background-color 300ms cubic-bezier(0.68, -0.55, 0.265, 1.55), transform 300ms cubic-bezier(0.68, -0.55, 0.265, 1.55);
		transform-origin: center; // Set rotation point to center
		transition: 0.2s background-color, height 0.1s;
	}

	&::before {
		top: calc(50% - 0.35rem); // Reduced distance
	}
	
	&::after {
		top: calc(50% + 0.155rem); // Reduced distance
	}
	
	&.active {
		&::before {
			background-color: #fff;
			transform: translateY(calc(0.15rem + 2.2px)) rotate(45deg); // Adjusted translateY
			transition: background-color 300ms cubic-bezier(0.68, -0.55, 0.265, 1.55), transform 300ms cubic-bezier(0.68, -0.55, 0.265, 1.55);
			height: 3px !important;
		}
	
		&::after {
			background-color: #fff;
			transform: translateY(calc(-0.15rem - 2.2px)) rotate(-45deg); // Adjusted translateY
			transition: background-color 300ms cubic-bezier(0.68, -0.55, 0.265, 1.55), transform 300ms cubic-bezier(0.68, -0.55, 0.265, 1.55);
			height: 3px !important;
		}
	}
}

#logo.light {
	color: #fff;
}

#menu-icon.light::before, #menu-icon.light::after {
	background-color: #fff;
}

#doscroll.light {
	border-color: #fff;
}

/* Header */

#logo {
	position: relative;
	margin-top: clamp(15px, 3vw, 1.5rem);
	margin-bottom: clamp(15px, 3vw, 1.5rem);
	margin-left: clamp(15px, 2vw, 1.5rem);
	margin-right: clamp(15px, 2vw, 1.5rem);
	max-width: 30rem;
	@include font($headingfont, 600, normal, 30px, 1.3);
	//font-size: clamp(16px, calc( 32px - 0.3vw ), 32px);
	align-self: start;
	justify-self: start;
	z-index: 20;
	color: #000;
	transition: 0.2s color;
}

header.light #logo {
	color: #fff;
	transition: 0.2s color;
	z-index: 0; // vet ej, men nödvändigt för att loggan ska hamna bakom menyn
}

header.light a {
	background-image: linear-gradient(rgb(255, 255, 255), rgb(255, 255, 255));
}

/* Typography */

article p, article ul {
	@include hyphernation(auto);
	hanging-punctuation: first;
	margin-bottom: 1.5rem;
}

footer p {
	@include hyphernation(none);
	font-size: 0.9em;
	line-height: 1.667em;
	display: block; /* Så p-taggen bara blir lika vid som innehållet */
	margin-bottom: 0;
	max-width: 30rem;
}

strong {
	font-weight: 900;
}

em {
	font-style: italic;
}

ul {
  list-style-type: disc;
  list-style-position: outside;
}

article li {
	margin-left: 1.1rem;
}

main h1 {
	@include font($headingfont, 700, normal, 2rem, 1.2);
	margin: 0 0 0.6rem 0;
	position: relative;
}

.index-blurb {
    @include font($headingfont, 700, normal, 2.2rem, 1.2);
    font-size: clamp(1.6rem, 6vw, 2.2rem);
    max-width: 36rem;
    margin-top: clamp(40px, 2vw, 120px);
    margin-bottom: clamp(30px, 3vw, 100px);
    
    &.tablebook {
        margin-bottom: $largegridspacing;
    }
}

.index-blurb p {
	@include hyphernation(none);
}

/* Links */

a {
	text-decoration: none;
	color: inherit;
	transition: color 200ms ease;
}

main p a, main h1 a, footer p a {
	text-decoration: underline;
	text-decoration-thickness: from-font;
	text-underline-offset: 0.08em;
	font-weight: 500;
}

a:hover {
	color: inherit;
}

.tags a {
	@include font($basefont, 400, normal, 0.9rem, 1.5);
	//text-decoration: none;
	@include hyphernation(none);
}

/* Arrow links */

.link-arrow-right {
	font-size: inherit;
	padding: 0.2em 1.3em 0.2em 0;
	text-decoration: none;
	line-height: 1.3!important;
	background-image: $arrow-right;
	background-repeat: no-repeat;
	background-position: right 0 top 0.2em;
	background-size: 1.3em;
	display: inline;
	transition: 0.1s ease-in-out;
	box-decoration-break: slice;
	-webkit-box-decoration-break: slice;
	position: relative;
	right: -0.2em;
}

.link-arrow-right:hover {
	background-position: right -0.3em top 0.2em;
	transition: 0.2s ease-in-out;
}

.link-arrow-left {
	font-size: inherit;
	padding: 0.2em 0 0.2em 1.3em;
	text-decoration: none;
	line-height: 1.3!important;
	background-image: $arrow-left;
	background-repeat: no-repeat;
	background-position: left 0 top 0.2em;
	background-size: 1.3em;
	display: inline;
	transition: 0.1s ease-in-out;
	box-decoration-break: slice;
	-webkit-box-decoration-break: slice;
	position: relative;
	left: -0.2em;
}

.link-arrow-left:hover {
	background-position: left -0.3em top 0.2em;
	transition: 0.2s ease-in-out;
}

.blog-navigation div {
	overflow: visible;
}

.paged-navigation {
	display: grid;
	grid-template-columns: 1fr 1fr;
	column-gap: 25%;
}

.paged-navigation {
	margin-top: 4.5rem;
}

.paged-navigation div:nth-of-type(even) {
	justify-self: end;
}

.paged-navigation a {
	@include font($headingfont, 600, normal, 1.5rem, 1.2);
}

@media (max-width: 1000px) {
	.paged-navigation {
		grid-template-columns: 1fr;
		column-gap: 1.5rem;
		margin-top: 2.5rem !important;
	}
}

h1.emoji {
	font-size: 6rem;
	position: relative;
	left: -0.25rem;
	max-width: none;
	margin: 0 auto 3rem auto;
}

h1.emoji span:first-child {
	position: relative;
	bottom: 0.3rem;
}

.tag-list {
	columns: auto 9rem;
}

img {
	pointer-events: none; /* Crudely makes it harder to right-click and download images. */
	touch-action: none;
}

.large-text {
	font-size: 120%;
	line-height: 1.4;
}

.huge-text {
	font-size: 120%;
	font-size: clamp(1.4rem, 2.2vw, 1.4rem);
}

.tile.hide {
	display: none; // Always hide tiles initially
}

@media only screen and (max-width: 1000px) {
	footer p {
		min-height: initial;
	}
}

@media only screen and (max-width: 600px) {
	.caption {
		min-width: initial!important;
	}
	.caption p {
		max-width: none;
		margin: 1.5rem 0;
	}
	.tags {
		margin-top: 1rem;
		margin-bottom: -3rem;
	}

	footer p {
		//margin: 0 0 1.5rem 0;
		min-height: initial;
	}
}

@import 'gallerypages';
@import 'basicpage';
@import 'blog';
@import 'multi-column';
@import 'animations';
@import 'menus';
@import 'defaultsection';
@import 'fslightbox';
@import 'darkmode';