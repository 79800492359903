@media (prefers-color-scheme: dark) {

	/* Dark theme */

	body {
		color: #fff;
		background-color: #202020;
	}

	#logo {
		color: #fff;
	}

	@media (hover: hover) {
		.article-page a h2, .blog-index a:hover h2 {
			background-image: linear-gradient(rgb(255, 255, 255), rgb(255, 255, 255));
		}
	}
	
	header a {
		background-image: linear-gradient(rgb(255, 255, 255), rgb(255, 255, 255));
	}

	#menu-icon::before, #menu-icon::after {
		background: #fff;
	}
	
	#mobile-menu li:not(.social-block) a {
		background-image: linear-gradient(rgb(000, 000, 000), rgb(000, 000, 000));
	}
	#menu .instagram-link {
		background-image: $instagram-white;
	}
	
	.index-blurb h1 a {
		background: #000 !important;
	}

	.image-container {
		background-color: #2C2C2C;
	}

	.link-arrow-right {
		background-image: $arrow-right-white;
	}

	.link-arrow-left {
		background-image: $arrow-left-white;
	}

	.fslightbox-container {
		background-color: #202020 !important;

		.fslightbox-svg-path {
			fill: #fff!important;
		}

		.fslightbox-loader div {
			border-color: #fff transparent transparent transparent !important;
		}
	}

	.slideshow .prevButton, .slideshow .nextButton {
		fill: #fff!important;
	}

	#doscroll {
        border-color: #fff; // Change border color to white
    }

}

