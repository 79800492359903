/* Menus */

header a {
    @include aniline;
}

header a:hover {
    @media (hover: hover) {
        @include aniline-hover;
    }
    color: inherit;
}
  
/* Menu */

body.noscroll {
    overflow: hidden;
}

#menu .submenu-toggle {
    cursor: default;
}

.current, .current:hover {
    //background-image: linear-gradient(rgb(156, 151, 148), rgb(156, 151, 148)) !important;
}

#menu-icon {
    display: block;
}

/* First the overlay */

#overlay {
    display: grid;
    justify-items: center;
    min-height: calc(var(--vh, 1vh) * 100);
    width: 100vw;
    background-color: #000;
    z-index: 30;
    position: absolute;
    overflow-x: hidden;
    overflow-y: auto;
    scroll-behavior: smooth;
}

#overlay.initial {
    // Get it out of the way at page load. We don't want the animation there, as it would flash the screen with black.
    transform: translateX(100%);
}

#overlay:not(.initial) {
    // Initial class removed after page load.
    animation-name: fadeOverlayOut;
    animation-duration: 400ms;
    animation-fill-mode: forwards;
}

#overlay.show {
    animation-name: fadeOverlayIn;
    animation-duration: 200ms;
    animation-fill-mode: forwards;
}

#overlay.show #menu {
    animation-name: fadeContentIn;
    animation-duration: 500ms;
    animation-fill-mode: forwards;
    display: block;
}

/* Then the overlay content. */

#menu {
	width: fit-content; /* Ensure the width of #menu is only as wide as its content */
    height: calc(var(--vh, 1vh) * 100);
    opacity: 0;
    display: none;
    z-index: 31;
}

#overlay.show #menu {
    opacity: 1;
}

#menu .mainmenu {
    display: flex;
    width: fit-content;
    flex-direction: column; // Align items vertically
    align-items: flex-start; // Align items to the left
    padding: 1.5em;
    padding: clamp(4em, 6vw, 6em) clamp(1.5em, 10vw, 6em);
}

#menu {
    li {
        list-style-type: none;
        margin-bottom: 0.8em;
        font-size: 1.6rem;
        font-size: clamp(1.4rem, 6vw, 1.8rem);
        line-height: 1.2;
        font-weight: 500;
        letter-spacing: -0.02rem;
        color: #fff;
    }

    .mainmenu > li:last-child {
        margin-bottom: 0;
    }

    .divider {
        margin: 0.4em 0 1em 0;
        width: 2em;
        height: 0.2em;
        background-color: #9C9794;
    }
}

#menu {
    .submenu-toggle {
        margin-bottom: 0;

        span {
            margin-bottom: 0.8em;
            display: inline-block;
            color: #9C9794;

            &::after {
                color: #9C9794;
                content: ":";
            }
        }
    }

    .submenu {
        margin-left: 1.5em;
    }
}

#menu .instagram-link {
    display: inline-block;
    position: relative;
    top: 0.2em;
    left: 0;
    width: 1.2em;
    height: 1.2em;
    background-image: $instagram-white;
    background-repeat: no-repeat;
    background-position: left center;
    background-size: auto;
    text-indent: -9999px; // Hide the text
}

#menu a, #menu a.current {
    background-image: linear-gradient(rgb(255, 255, 255), rgb(255, 255, 255));
    background-size: 0 0.1em;
}

#menu a.current {
    background-size: 100% 0.1em;
}

/* Animations */

@keyframes fadeOverlayIn {
    0% { opacity: 0; transform: translateX(100%); }
    1% { opacity: 0; transform: translateX(0); }
    100% { opacity: 1; transform: translateX(0); }
}

@keyframes fadeOverlayOut {
    0% { opacity: 1; transform: translateX(0); }
    99% { opacity: 0; transform: translateX(0); }
    100% { opacity: 0; transform: translateX(100%); }
}

@keyframes fadeContentIn {
    0% { transform: translateY(5%); opacity: 0; }
    20% { transform: translateY(5%); opacity: 0; }
    100% { transform: translateY(0); opacity: 1; }
}