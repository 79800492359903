.manual-columns {
	display: flex;
	gap: 2rem;
	align-items: flex-start;
}

.manual-columns .column {
	flex: 1;
	display: flex;
	flex-direction: column;
	gap: 1rem;
	min-width: 0;
}
  

.manual-columns-article h1 {
	margin-bottom: 3rem;
}

/* Optional: Normalize spacing */
.manual-columns h2, {
	margin: 1rem 0 0 0;
	font-weight: 700;
	font-size: 1.3rem;
}

.manual-columns h3 {
	margin: 1rem 0 0 0;
	font-weight: 700;
	font-size: 1rem;
}
  
.manual-columns p,
.manual-columns ul,
.manual-columns li {
	margin: 0;
	line-height: 1.6;
}

.manual-columns li {
    margin-left: 1.4rem;
    list-style: lower-alpha;
    
    &::marker {
        font-weight: 700; /* Normal weight for bullets */
        font-size: 0.9em; /* Slightly smaller bullets */
    }
}
  