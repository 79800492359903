/* basic page */

article.basic-page {
	max-width: 40rem;
}

article.basic-page p {
	max-width: none;
}

article.basic-page .basic-image {
	max-width: 28rem;
	margin-bottom: 3rem;
}

article.basic-page .contact-info {
	max-width: 28rem;
	margin-top: 3rem;
}

article.basic-page .basic-image p, .basic-page .video-container p {
	font-size: 0.8rem;
	margin-top: 1rem;
}

.basic-page .video-container {
    height: auto;
    //max-width: 24rem;
	margin-bottom: 3rem;
    
    video {
        width: 100%;
        height: auto;
        display: block;
    }
}

@media only screen and (max-width: 1000px) {
	article.basic-page {
		margin-bottom: 3rem;
	}
}

@media only screen and (max-width: 600px) {
	article.basic-page {
		grid-template-columns: 1fr;
		row-gap: 1rem;
		margin-bottom: 3rem;
	}
}