
/* Theming FSLightbox */

.lightbox {
	cursor: zoom-in;
}

.fslightbox-toolbar-button:not(:last-child), .fslightbox-slide-number-container {
  display: none !important;
}

.fslightbox-container {
	background: #fff !important;
}

.fslightbox-toolbar, .fslightbox-slide-btn {
	background: rgba(255,255,255,0) !important;
}

.fslightbox-svg-path {
	fill: #000!important;
}

.fslightbox-slide-btn-container-previous {
	padding-left: 0!important;
}

.fslightbox-slide-btn {
	padding: 12px 10px!important;
}

.fslightbox-toolbar-button {
	padding: 10px!important;
}

.fslightbox-nav, .fslightbox-toolbar {
	height: 50px !important;
}

.fslightbox-toolbar-button {
	width: 50px !important;
}

.fslightbox-slide-btn-container-next {
	padding-right: 0!important;
}

.fslightbox-loader div {
	border-color: #000 transparent transparent transparent !important;
}

.fslightbox-caption {
	background: none !important;
}

.fslightbox-caption-inner {
	color: #000 !important;
	font: inherit !important;
}

@media (max-width: 1000px) {
	.fslightbox-slide-btn-container {
		display: none !important; /* Hide arrows */
	}
}
