/* Blog */


/* Tag page */

h1.tagsearch {
	margin-top: clamp(1.5rem, 5vw, 3rem);
}

/* Blog index */

article.blog-index {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr;
	gap: 5rem;
	gap: clamp(100px, 6vw, 220px);
}

@media (max-width: 1500px) {
	article.blog-index {
		grid-template-columns: 1fr 1fr 1fr;
	}
}

@media (max-width: 1000px) {
	article.blog-index {
		grid-template-columns: 1fr 1fr;
	}
}

@media (max-width: 600px) {
	article.blog-index {
		grid-template-columns: 1fr;
		//margin-top: 0.5rem;
		row-gap: 3rem;
	}
}

article.blog-index .preview {
	margin: 0.6rem 0;
	font-size: 1.08rem;
	line-height: 1.45;
}

article.blog-index .meta {
	font-size: 0.8rem;
	line-height: 1.7;
	margin-bottom: 0;
}

article.blog-index .text-only h2 {
	font-size: 1.6rem !important;
}

.text-only {
	align-self: flex-start;
	margin-top: 0.2rem;
	font-size: 0.9rem;
}

article.blog-index .image-container {
	margin-bottom: 1.15rem;
	clip-path: polygon(0% 0%,100% 0%,100% 100%,0% 100%);
	transition: all 0.5s;
}

article.blog-index a:hover .image-container {
	clip-path: polygon(0 0, 88% 0, 95% 100%, 0% 100%);
	transition: all 0.3s;
}

article.blog-index h2 {
	@include font($headingfont, 600, normal, 1.3rem, 1.2);
	margin-bottom: 0.15rem;
}

.blog-index a h2 {
	@include aniline;
}

.blog-index a:hover h2 {
	@include aniline-hover;
}

@media (hover: none) {
	.blog-index a h2 {
		background: none;
	}
}

/* Blog page */

article.blog-page {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.blog-text {
	width: 100%;
	max-width: 34rem;
}

article.blog-page h1 {
	@include font($headingfont, 700, normal, 2rem, 1.2);
	width: 100%;
	max-width: 24rem;
	margin-bottom: 3rem;
}

article.blog-page h1.big-headline {
	@include font($headingfont, 700, normal, 2.4rem, 1.2);
}

article.blog-page h2 {
	@include font($headingfont, 600, normal, 1.5rem, 1.2);
	width: 100%;
	max-width: 24rem;
	margin-bottom: 1.5rem;
}

article.blog-page p {
	max-width: none;
}

article.blog-page ul {
	list-style-type: none;
	margin: 3rem 0;
}

article.blog-page li {
	margin-bottom: 1.5rem;
	margin-left: 0;
	display: block;
}

article.blog-page .meta {
	font-size: 0.9rem;
	margin-top: 3rem;
	text-align: left;
	align-self: center;
	width: 100%;
	max-width: 34rem;
}

article.blog-page .tags {
	font-size: 0.9rem;
	text-align: left;
	align-self: center;
	width: 100%;
	max-width: 34rem;
	margin: 0 0 1.5rem 0;
}

article.blog-page .tags li {
	display: inline;
	margin-right: 0.5rem;
}

article.blog-page .tags a {
	text-decoration: underline;
	text-decoration-thickness: from-font;
	text-underline-offset: 0.08em;
	font-weight: 500;
}

article.blog-page li h1, article.blog-page li h2 {
	margin-bottom: 0.5rem;
}

article.blog-page .blog-images {
	display: grid;
	grid-template-columns: 1fr;
	margin-top: 4.5rem;
	gap: 2rem;
	width: 100%;
	max-width: 750px;
}

.blog-images .tile.hide {
	display: none;
}

article.blog-page .blog-image {
	display: flex;
	justify-content: center;
}

article.blog-page .blog-images .tile {
	width: 100%;
}

article.blog-page .blog-images .portrait {
	width: calc(100% * var(--tile-aspect-ratio));
}

article.blog-page .blog-image p {
	font-size: 0.8rem;
}

.toc-link {
	margin: 4.5rem auto 3rem auto;
	max-width: 40rem;
}

@media only screen and (max-width: 1000px) {
	article.blog-page {
		margin-top: 1.5rem;
		margin-bottom: 3rem;
	}
}

@media only screen and (max-width: 600px) {
	article.blog-page {
		grid-template-columns: 1fr;
		row-gap: 1.5rem;
		margin-bottom: 1.5rem;
	}
	article.blog-page .blog-images {
		margin-top: 0;
		gap: 1rem;
	}
	article.blog-page .blog-images .portrait {
		width: 100%;
	}
	article.blog-page h1 {
		margin-bottom: 1.5rem;
	}
	article.blog-page .meta {
		margin-top: 0.5rem;
	}
	article.blog-page .tags {
		margin-top: -1.5rem;
	}
}