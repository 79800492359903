/* Animations */

.lazyload, .lazyloading, .lazyloaded, .index-title {
	opacity: 0;
	will-change: opacity;
}

.lazyloaded {
	transform: translateY(0);
	animation: fadeIn 0.5s ease forwards;
}

.lazyloaded.slideup, .lazyloaded.slideup + .index-title {
	clip-path: polygon(0 0, 50% 0, 61% 100%, 0% 100%);
	animation: slideUp 0.5s ease forwards;
}

@media (min-width: 600px) {
	.lazyloaded.slideUnfinished {
		clip-path: polygon(0 0, 50% 0, 61% 100%, 0% 100%);
		animation: slideUp 0.5s ease forwards;
	}
}

@media (max-width: 600px) {
	.lazyloaded.slideUnfinished {
		clip-path: polygon(0 0, 50% 0, 61% 100%, 0% 100%);
		animation: slideUnfinished 0.5s ease forwards;
	}
}

@keyframes slideUp {
	0% { opacity: 0; }
	100% { clip-path: polygon(0 0, 100% 0, 100% 200%, 0% 200%); opacity: 1; }
}

@keyframes slideUnfinished {
	0% { opacity: 0; }
	100% { clip-path: polygon(0 0, 100% 3%, 100% 100%, 0% 100%); opacity: 1; }
}

@keyframes fadeIn {
	0% { opacity: 0.000001; }
	100% { opacity: 1; }
}