/* Default section */

.section-wrapper:first-child {
	margin-top: clamp(1.5rem, 5vw, 3rem);
}

.section-wrapper:not(:last-child) {
	margin-bottom: clamp(3rem, 5vw, 4.5rem);
}

.section-wrapper:last-child {
	margin-bottom: 3vw;
}

.section-wrapper.text-section {
	margin-bottom: 5vw;
}

.section-wrapper h1 {
	@include font($headingfont, 700, normal, 2.6rem, 1.2);
	margin-bottom: 1.5rem;
	max-width: 40rem;
}

.section-wrapper h2 {
	@include font($headingfont, 700, normal, 1.5rem, 1.25);
	margin-bottom: 1.5rem;
	max-width: 40rem;
}

.section-wrapper p {
	max-width: 40rem;
}

@media only screen and (max-width: 1194px) {
	.section-wrapper h1 {
		@include font($headingfont, 700, normal, 1.8rem, 1.25);
	}
}

@media only screen and (max-width: 600px) {
	.section-wrapper h1 {
		@include font($headingfont, 700, normal, 1.6rem, 1.25);
	}
}