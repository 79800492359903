/* All gallery pages */

.image-container {
	width: 100%;
	background-color: #f7f7f7;
}

.image-container img {
	width: 100%;
	height: auto;
	display: block; /* This removes extra height in div */
}

.image-caption {
	margin-top: 0.5rem;
}

.image-caption p {
	@include font($basefont, 400, normal, 0.9rem, 1.5);
	margin: 0!important;
}


@media (max-width: 1000px) {
	.prev-gallery, .next-gallery {
		width: 2.5rem;
	}
}

@media (max-width: 600px) {
	.prev-gallery, .next-gallery {
		width: 1rem;
	}
	.caption.big p {
		margin: 0 0.05em; // Looks better on iphone
		width: calc(100% - 0.1em);
	}
}

.next-up {
	margin: 12vw 0 2vw 0;
	margin: calc(1rem + 12vw) 0 calc(1rem + 2vw) 0;
	//display: none;
}

.next-up a {
	text-decoration: none;
}

/* Masonry */

.masonry {
	display: flex;
	flex-direction: row;
	row-gap: $gridspacing;
	/* Placeholder height for CLS */
	min-height: 100vh;
  
	.col {
	  flex: 1;
	  margin-right: $gridspacing;
	  &:last-child {
		margin-right: 0;
	  }
	}
  
	.col .tile.hide {
	  display: none; /* Don't show initially */
	}
  
	.tile {
	  display: block;
	  width: 100%;
	  margin-bottom: $gridspacing;
	  &:last-of-type {
		margin-bottom: 0;
	  }
	}
  
	@media ( max-width: 600px ) {
	  .tile {
		margin: 0 0 15px 0;
	  }
	}
  }
  
/* mosaic */

.mosaic {
	display: flex;
	flex-direction: column;
	row-gap: $gridspacing;
	/* Placeholder height for CLS */
	min-height: 100vh;
  
	.row {
	  display: flex; // Display the tiles in a row
	  align-items: flex-start; // Make all items in the row have the same height
	  flex-wrap: nowrap; // Prevent the tiles from wrapping onto the next line
	}
  
	.tile {
		position: relative;
		display: flex;
		flex-direction: column;
		margin-right: $gridspacing;
		width: calc(var(--tile-aspect-ratio) * 200vw); // Dynamically set the width based on the aspect ratio
	}
  
	.tile:last-child {
	  margin-right: 0; // Remove the right margin for the last tile
	}
  
	.row .tile.hide {
	  display: none; /* Don't show initially */
	}
  }

  /* Visual Diary */

  .visualdiary .tile .overlay {
	position: absolute;
	bottom: 0;
	left: 0;
	z-index: 20;
	//opacity: 0;
	transition: opacity 0.2s;
  }

  .visualdiary .tile:hover .overlay {
	//opacity: 1;
  }

  .visualdiary .tile .overlay p {
	margin: 0.3rem 0.5rem;
	color: #fff;
  }

  .visualdiary .tile .overlay p:first-child {
	font-size: 0.8rem;
  }

/* Grid pages */

.grid-page {

	.grid {
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		grid-gap: $gridspacing;
	}
	@media ( max-width: 1000px ) {
		.grid {
			grid-template-columns: repeat(3, 1fr);
		}
	}
	@media ( max-width: 600px ) {
		.grid {
			grid-template-columns: repeat(1, 1fr);
		}
	}

}

/* Slideshow */

.slideshow {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 90vmin;
  
    &[data-slideshow-nav="manual"] .row {
      cursor: pointer;
    }
  
    .prevButton, .nextButton {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      background: none;
      border: none;
      cursor: pointer;
      z-index: 10; /* Ensure arrows are above rows */
    }

    .light {
        fill: #fff;
    }
    
    .row {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: $mediumgridspacing;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
	  cursor: e-resize;
      
      &.hide {
        display: none !important;
      }
    }
  
    .tile {
      position: relative; /* Changed from absolute */
      display: flex !important;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: auto; /* Allow height to adjust */
      max-height: 100%;
      width: auto; /* Allow width to adjust based on aspect ratio */
      
      div {
        align-self: center;
        justify-self: center;
      }
    
      .image-caption {
        text-align: left;
        align-self: flex-start;
      }
      
      &.hide {
        display: none !important;
      }
    }
    
    .prevButton {
      left: 0px;
    }
    
    .nextButton {
      right: 0px;
    }
    
    /* Convert to regular column layout on narrow screens */
    @media (max-width: 600px) {
      /* Reset slideshow container */
      height: auto;
      min-height: unset;
      overflow: visible;
      
      /* Hide slideshow controls */
      .prevButton, .nextButton {
        display: none !important;
      }
      
      /* Reset row to regular flow */
      .row {
        position: static !important;
        display: block !important;
        flex-direction: column;
        top: auto !important;
        left: auto !important;
        transform: none !important;
        height: auto;
        margin-bottom: 0;
        opacity: 1 !important;
        
        /* Show all rows */
        &.hide {
          display: block !important;
        }
      }
      
      /* Reset tile styles for column layout */
      .tile {
        position: static;
        display: block !important;
        width: 100% !important;
        max-width: 100% !important;
		height: auto!important;
        margin-bottom: $gridspacing;
        
        /* Show all tiles */
        &.hide {
          display: block !important;
        }
        
        /* Make images responsive */
        img {
          width: 100%;
          height: auto;
          max-height: none;
        }
      }
    }
}

.tablebook {
	margin-top: clamp(15px, 2vw, 100px);
	display: flex;
	flex-direction: column;
	row-gap: $largegridspacing;
  
	.image-container {
	  	width: auto;
	  	height: auto;
	  	aspect-ratio: var(--tile-aspect-ratio);
	  	margin: auto;
  
	  a {
		display: flex; // Ensure anchor is a flex container
		width: 100%;   // Allow the anchor to fill the container
		height: auto;
	  }
  
	  img {
		width: 100%;
		height: auto;
		display: block;
		object-fit: cover;
	  }
	}
  
	.row {
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap; // Allow items to wrap
		justify-content: center;
		align-items: center;
		width: auto;
		max-width: 100%;
		gap: $largegridspacing;
	  
		@media (max-width: 600px) {
		  flex-direction: column; // Stack items vertically on small screens
		  flex-wrap: wrap;
		}
	  }
	  
	.tile {
	  	flex: 1 1 calc(40vw / var(--cols) * var(--tile-aspect-ratio));
	  	width: auto;
	  	max-width: 1000px;
	  	max-width: clamp(300px, calc(100vh * var(--tile-aspect-ratio)), 1000px); // Scale width for landscape
	  	max-height: 100%;
	  	height: auto;
	  	display: flex;
	  	align-content: center;
  
	  @media (max-width: 600px) {
		flex: 1 1 100%; // Make tiles take full width on small screens
		width: 100%;
		max-width: none;
		min-height: calc(10vw * var(--tile-aspect-ratio)); // Hack to prevent collapsing tiles on reverse scroll
	  }
  
	  &.portrait {
		max-width: clamp(calc(300px * var(--tile-aspect-ratio)), calc(100vh * var(--tile-aspect-ratio)), calc(1000px * var(--tile-aspect-ratio))); // Scale width for portrait
	  }
	}
  
	.row .tile.hide {
	  display: none;
	}
}

  
#doscroll {
    position: fixed;
	z-index: 15;
    bottom: 20px; // Adjust the bottom value as needed
    left: 50%;
    transform: translateX(-50%) rotate(45deg); // Center horizontally and rotate to form an arrow
    width: 10px;
    height: 10px;
    border: solid black;
    border-width: 0 4px 4px 0;
    display: inline-block;
    padding: 10px;
    -webkit-transform: translateX(-50%) rotate(45deg); // Ensure compatibility with WebKit browsers
	animation: bob 2s ease-in-out infinite;
	display: none;
	opacity: 0;
	transition: opacity 0.5s;
}

@keyframes bob {
    0%, 40%, 80%, 100% {
        transform: translateX(-50%) rotate(45deg) translateY(0) translateX(0);
    }
    20% {
        transform: translateX(-50%) rotate(45deg) translateY(-10px) translateX(-10px);
    }
}